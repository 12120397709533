import React, { useState, useEffect } from 'react';
import Layout from '../components/layout.tr';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContactForm from '../components/contact-form.tr';
import PageHeader from '../components/pageHeader';
import Pin from '../components/pin';
import { isMobile } from 'react-device-detect';

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");

export default ({ location }) => {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [company, setCompany] = useState(null);
  const [subject, setSubject] = useState("Toplantı Talebi");
  const [message, setMessage] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (ev) => {
    setLoading(true);
    let fields = {
      NAME: name,
      EMAIL: email,
      PHONE: phone,
      COMPANY: company,
      SUBJECT: subject,
      MESSAGE: message
    }

    // let group = params.get('group');
    // if(group){
    //   fields['group[24473]'] = group;
    // }
    addToMailchimp(email, fields).then(res => {
      setLoading(false);
      $('#wf-form-contact-us-form').hide();
      $('.success-message').show();
    }).catch(() => {
      setLoading(false);
      $('#wf-form-contact-us-form').hide();
      $('.w-form-fail').show();
    });

    ev.preventDefault();
    return false;
  }

  useEffect(() => {

    // Fade Up 5 (Footer blocks)
    var fadeUp5 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp5
      .add({
        targets: '.footer-block',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 900,
        delay: (el, i) => 50 + 80 * i
      });


    // Fade Up 5 (Product Preview)
    var fadeUp6 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp6
      .add({
        targets: ['.field-label-contact-us', '.contact-us-usp-paragraph'],
        translateY: [25, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => i
      });



    // Play animation when scrolled into view
    $('#inview5').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp5.play();
        fadeUp5.restart();
      } else {
      }
    });

    // Play animation when scrolled into view
    $('.contact-us-container').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp6.play();
        fadeUp6.restart();
      } 
    });

  }, []);


  return (
    <Layout currentPath='/iletisim' headerLight={true} footerLight={false} fullPage={true} bodyClassName="body" footerWhiteLogo={true}>
      <SEO title="İletişim" />
      <div className="section-2 wf-section">
        <div className="contact-us-container">
          <div className="contact-us-form-container">
            <div className="div-block-33">
              <div className="image-13" >
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src={"https://assets1.lottiefiles.com/packages/lf20_ddioifue.json"}
                ></lottie-player>
              </div>

              <h1 className="heading-12">Sorularınız ve talepleriniz için</h1>
            </div>
            <div className="w-form">
              <form id="wf-form-contact-us-form" name="wf-form-contact-us-form" data-name="Hire Form" className="form contact-us-form" onSubmit={onSubmit}> 
                <div className="div-block-13">
                  <div className="form-sub-container _1">
                    <label htmlFor="Name-3" className="field-label-contact-us">Ad Soyad</label>
                    <input type="text" className="text-field-contact-us w-input" autoComplete="off" maxLength="256" name="Name" data-name="Name" id="Name-3" required="" onChange={(ev) => setName(ev.target.value)} disabled={isLoading} />
                    <label htmlFor="Email" className="field-label-contact-us">E-Posta</label>
                    <input type="email" className="text-field-contact-us w-input" maxLength="256" name="Email" data-name="Email" id="Email" required="" onChange={(ev) => setEmail(ev.target.value)} disabled={isLoading} />
                  </div>
                  <div className="form-sub-container">
                    <label htmlFor="Company" className="field-label-contact-us">Şirket</label>
                    <input type="text" className="text-field-contact-us w-input" autoComplete="off" maxLength="256" name="Company" id="Company" onChange={(ev) => setCompany(ev.target.value)} disabled={isLoading} />
                    <label htmlFor="Phone-3" className="field-label-contact-us">Cep Telefonu</label>
                    <input type="tel" className="text-field-contact-us w-input" maxLength="256" name="Phone" data-name="Phone" id="Phone-3" required="" onChange={(ev) => setPhone(ev.target.value)} disabled={isLoading} />
                  </div>
                </div>
                <label htmlFor="Type-of-team-3" className="field-label-contact-us">Konu</label>
                <select id="Subject" name="Subject" data-name="Type of team" required="" className="select-field-contact-us w-select" onChange={(ev) => setSubject(ev.target.value)} disabled={isLoading} >
                  <option value="Demo Talebi">Toplantı Talebi</option>
                  <option value="Demo Talebi">Demo Talebi</option>
                  <option value="Danışmanlık">Danışmanlık</option>
                  <option value="Diğer">Diğer</option>
                </select>
                <label htmlFor="Message" className="field-label-contact-us">Açıklama</label>
                <textarea maxLength="5000" id="Message" name="Message" data-name="Message" className="text-field-contact-us w-input" onChange={(ev) => setMessage(ev.target.value)} disabled={isLoading} ></textarea>
                <input type="submit" value="Gönder" data-wait="Please wait..." className="btn big contact-us-button cta-form w-button" />
              </form>
              <div className="success-message contact-us w-form-done">
                <div className="success-text">Teşekkürler!<br />Mesajınız bize ulaştı.</div>
                <div className="success-sub">En kısa sürede geri dönüş sağlayacağız.</div>
              </div>
              <div className="error-message w-form-fail"><div>Çok üzgünüz, mail gönderilirken hata alındı. Lütfen daha sonra tekrar deneyiniz.</div>
              </div>
            </div>
          </div>
          <div className="contact-us-info-container">
            <div className="contact-us-usp-container">
              <div className="contact-us-usp-heading">
                <h2 className="heading-13">Adres</h2>
              </div>
              <p className="contact-us-usp-paragraph">Sanayi, İstanbul Teknoloji Geliştirme Bölgesi, Teknopark Blv No:1, 34906 Pendik/İstanbul</p>
              <div className="map"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12059.165338557976!2d29.3141583!3d40.9203209!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8035a3d64cef93dc!2sTechnopark%20Istanbul!5e0!3m2!1sen!2str!4v1629134030168!5m2!1sen!2str" width="400" height="300" allowfullscreen="" loading="lazy"></iframe></div>
            </div>
            <div className="contact-us-usp-container">
              <div className="contact-us-usp-heading">
                <h2 className="heading-13">Telefon</h2>
              </div>
              <p className="contact-us-usp-paragraph"><a href="tel:+905458717767">+90 (545) 871 7767</a></p>
            </div>
            <div className="contact-us-usp-container">
              <div className="contact-us-usp-heading">
                <h2 className="heading-13">Mail</h2>
              </div>
              <p className="contact-us-usp-paragraph"><a href="mailTo:info@fibootech.com">info@fibootech.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}